<template>
  <div id="HomePage">
    <!-- 合作伙伴 -->
    <div
      id="customer"
      class="container-fuild"
    >
      <div class="container customer-container">
        <p class="customer-title text-center">合作伙伴</p>
        <div class="swiper-container customer-swiper hidden-xs">
          <div class="swiper-wrapper">
            <div
              v-for="(item,index) in customerList"
              :key="index"
              class="swiper-slide customer-block"
            >
              <div class="customer-logo">
                <img
                  class="center-block"
                  :src="item.logo"
                  alt="logo"
                >
              </div>
              <!--                            <div class="customer-yh">-->
              <!--                                <img src="@/assets/img/yinhao.png" alt="引号">-->
              <!--                            </div>-->
              <div class="customer-content1">
                <small>{{ item.content }}</small>
              </div>
              <div class="customer-content2">
                <a :href="item.url">{{ item.title }} {{ item.url }}</a>
              </div>
            </div>
          </div>
          <!-- 如果需要导航按钮 -->
          <!--                    <div class="swiper-button-prev"></div>-->
          <!--                    <div class="swiper-button-next"></div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'
import { WOW } from 'wowjs'

export default {
  name: 'Cooperation',
  data () {
    return {
      customerList: [
        {
          logo: require('@/assets/img/wmdigit.png'),
          title: '欢迎点击',
          content: '作为店驰的战略合作伙伴，元芒数字以人工智能和大数据为突破点，面向零售企业，提供智能零售解决方案的服务商，赋能零售企业完成智能化升级，帮助他们提升销售业绩，减少人力成本。团队深耕零售行业15年，为客户提供包括智能生鲜自助收银设备、智能生鲜自助称重设备、智能收银软件、小程序商城、营销中台、会员中台、订单中台综合管理后台和咨询服务等一整套产品和服务。',
          url: 'http://www.wmdigit.com'
        }
      ]
    }
  },
  mounted () {
    /* customer-swiper */
    new Swiper('.customer-swiper', {
      loop: true, // 循环模式选项
      slidesPerView: 1,
      // 自动播放
      autoplay: {
        delay: 6000000000,
        // delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: false
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true // 修改swiper的父元素时，自动初始化swiper
    })
    /* wowjs动画 */
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    })
    wow.init()
  }
}
</script>
<style scoped>
/* 整体盒子 */
#HomePage {
  width: 100%;
}

/* 合作伙伴 */
#customer {
  padding: 50px 0;
  box-sizing: border-box;
  background: #efefef;
  transition: all ease 0.6s;
}

#customer .customer-title {
  font-size: 30px;
  color: rgb(102, 102, 102);
  margin: 0 0 30px;
}

#customer .customer-block {
  /*background: #fff;*/
  padding: 30px;
}

#customer .customer-logo img {
  height: 50px;
  /*border: 1px solid #ccc;*/
  margin-bottom: 10px;
}

#customer .customer-yh img {
  width: 34px;
  height: 34px;
}

#customer .customer-content1 {
  /*padding-bottom: 20px;*/
  /*border-bottom: 1px solid #0ce9f1;*/
}

#customer .customer-content1 > small {
  font-weight: normal;
  font-size: 14px;
  /*margin: 0px 16px;*/
  display: block;
}

#customer .customer-content2 {
  padding-top: 10px;
  /*float: right;*/
}

/* 媒体查询（手机） */
@media screen and (max-width: 768px) {
  #customer {
    padding: 30px 0;
    box-sizing: border-box;
    background: #fff;
  }

  #customer .customer-title {
    font-size: 20px;
    font-weight: bold;
  }

  #customer .customer-logo img {
    height: 40px;
  }

  #customer .customer-block {
    padding: 30px;
  }

  #customer .customer-block > div {
    padding: 30px 0;
  }
}

/* 媒体查询（平板） */
/*@media screen and (min-width: 767px) and (max-width: 996px) {*/
@media screen and (max-width: 767px) {
  #customer .customer-title {
    font-size: 24px;
  }
}
</style>
